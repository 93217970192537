<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
    Become Instructor
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="BecomeInstructorFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col cols="12">
            <b-form-group
      
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules=""
                >
                <quill-editor
     
                v-model="BecomeInstructorForm.description"
    />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
            <b-col cols="12" class="">
            <div class="border rounded p-2" style="margin:0px">
              <h4 class="mb-1">header image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                 
                  <b-card-text class="my-50"> </b-card-text>
                  <div class="d-inline-block">
                  <b-form-group
                      label="header image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                   <validation-provider
                  #default="{ errors }"
                  name="Tab Image "
                  rules="required"
                >
                    <b-form-file
                      ref="refInputE2"
                      v-model="BecomeInstructorForm.image"
                      accept=".jpg, .png, .gif, .jpeg"
                      placeholder="Choose file"
                      @input="inputImageRendererTab"
                    />
                     <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
                    <b-form-group
                      label="Image Caption"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="BecomeInstructorForm.image_caption"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="Course Name"
                  rules="required"
                >
              <b-form-input id="blog-edit-title" v-model="BecomeInstructorForm.title" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <!-- <b-col md="4">
            <b-form-group
              label="Description"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="BecomeInstructorForm.description"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
        
          <b-col cols="4">
              <b-form-group
                label="Keywords"
                label-for="blog-content"
                class="mb-2"
              >
              
                  <b-form-tags v-model="BecomeInstructorForm.keywords" />
                
              </b-form-group>
            </b-col>
             <b-col md="4">
            <b-form-group
              label=" Meta Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="meta_title"
                  rules="required"
                >
              <b-form-input id="blog-edit-title" v-model="BecomeInstructorForm.meta_title" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <b-col md="4">
            <b-form-group
              label="Meta Description"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="In-Link Name"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="BecomeInstructorForm.meta_description"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
          <b-col cols="4">
              <b-form-group
                label=" Meta Keywords"
                label-for="blog-content"
                class="mb-2"
              >
              
                  <b-form-tags v-model="BecomeInstructorForm.meta_keywords" />
                
              </b-form-group>
            </b-col>
        
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
           
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
    BFormTags
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from '@/store'
import Vue from "vue";
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);
    const Tabimage = ref("media/svg/files/blank-image.svg");
   
    const form = ref({
      description: "",
      title: "",
      meta_title: "",
      meta_description: '',
      meta_keywords: '',
      keywords: "",
      image: "",
      image_caption: "",
    
    });
    const BecomeInstructorForm=reactive(form)
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
     const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
     const BecomeInstructorFormvalidate=ref()
     store.dispatch("setting/getBecomeInstructor").then((response) => {
  
  form.value=response.page_content

  form.value.meta_keywords=  response.page_content.meta_keywords.split(',')


    })

    const save = () => {
      
 
      const formData = new FormData();

    
        
         BecomeInstructorFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("title", BecomeInstructorForm.value.title);
formData.append("description", BecomeInstructorForm.value.description);
formData.append("meta_title", BecomeInstructorForm.value.meta_title);
formData.append("title", BecomeInstructorForm.value.title);
formData.append("meta_description", BecomeInstructorForm.value.meta_description);
formData.append("image", BecomeInstructorForm.value.image);
formData.append(" meta_keywords", BecomeInstructorForm.value.meta_keywords);
formData.append("image_caption", BecomeInstructorForm.value.image_caption);
formData.append("keywords", BecomeInstructorForm.value.keywords);

     
           store.dispatch('setting/addBecomeInstructor',formData)
        .then(response => {
           Vue.swal({
                       title: " Updated ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
  
        }})
    };
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      BecomeInstructorForm,
      inputImageRenderer,
      BecomeInstructorFormvalidate,
    
      form,
     

      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
      BFormTags,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
